.mainrepair{
  background-color: white;
  border-radius: 15px;
  height: 300px;
  margin-left: 15px;
}

.dropbtn:focus {
    box-shadow: none !important;
  }
  
  .dropbtn {
    padding: 0 !important;
    background-color: white;
    color: gray;
  }
  
  .dropbtn::before {
    content: none !important;
  }
  
  .dropbtn:hover {
    color: black;
  }
  
  .dropbtn:focus {
    box-shadow: none;
  }
  
  .dropbtn {
    background-color: transparent !important;
    border: none !important;
    font-size: 30px;
    margin-bottom: 30px;
    margin-right: 20px;
  
  }
  
  .dropmenu {
    width: 250px;
    margin-top: 45px !important;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.10);
    text-decoration: none;
    list-style: none;
  }
  .fontsize{
    font-size: 15px;
  }

  .fontsize p{
    margin-bottom: 5px;
  }
  @media only screen and (max-width: 1024px) {
    .mainrepair{
      margin-left: 0;
    }
  }
  @media only screen and (max-width: 600px) {
    .fontsize{
      font-size: 12px;
    }
  }
  @media only screen and (max-width: 425px) {
    .fontsize{
      font-size: 12px;
    }
  }
  @media only screen and (max-width: 375px) {
    .display{
      display: inline !important;
      
    }
  }