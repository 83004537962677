.displayhead {
    z-index: 10;
    background-color: #F2F4F9;
    width: 79%;
    position: fixed;
    padding-top: 35px;
    padding-bottom: 35px;
}

.selectbox input::placeholder {
    color: #5B93FF;
}

.locationlist {
    position: relative;
    top: 150px;
}

.formgroup {
    margin-bottom: 16px;
}

.searchfield {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.searchdisplay {
    height: 40px;
}

.search {
    height: 100%;
}

.closebtn,
.closebtn:hover {
    border: 1px solid #5B93FF;
    color: #5B93FF;
    border-radius: 10px;
    background-color: transparent;
    padding: 7px 25px;
}

.searchbtn {
    background-color: white;
    border: none;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    line-height: 0;
}

.searchbtn:hover {
    background-color: white !important;
}


.addbtn {
    background-color: #5B93FF;
    border-radius: 10px !important;
    color: white;
    margin-left: 10px;
    border: none;
    padding: 10px 10px;
    display: inline-flex;
    align-items: center;
}


/* modal */
.modal-dialog {
    max-width: 900px !important;
    height: 1000px !important;

}

.label {
    margin-bottom: 15px;
}

.closebtn {
    border: 1px solid #5B93FF;
    color: #5B93FF;
    border-radius: 10px;
    background-color: transparent;
    padding: 7px 25px;
}

.createbtn {
    border: 1px solid #5B93FF;
    color: white;
    border-radius: 10px;
    background-color: #5B93FF;
    padding: 7px 25px;
}

.crossBtn {
    background-color: #eb7777;
    border-radius: 50%;
    padding: 13px !important;
}

input[type="radio"] {
    display: none;
}

input[type="radio"]:checked+label {
    background-color: #5B93FF;
    color: #fff;
}

/* media screen responsive */

@media only screen and (max-width: 1300px) {
    .divWidth {
        width: 1250px !important;
    }

    .displayhead {
        width: 81%;
        padding-right: 25px;
    }
}

@media only screen and (max-width: 1200px) {
    .displayhead {
        width: 72%;
        padding-right: 25px;
    }
}

@media only screen and (max-width: 1024px) {
    .divWidth {
        width: 1200px !important;
    }

    .location {
        overflow: auto;
    }

    .displayhead {
        width: 72%;
        padding-right: 25px;
    }

    .searchinput {
        width: 65% !important;
    }
}

@media (max-width: 920px) {
    .displaysearch {
        display: inline !important;
    }

    .searchinput {
        width: auto !important;
    }

    .locationlist {
        top: 175px;
    }
}

@media only screen and (max-width: 768px) {
    .divWidth {
        width: 1100px !important;
    }

    .locationhead {
        display: inline !important;
    }

    .addbtn {
        font-size: 12px;
    }

    .displayhead {
        margin-bottom: 10px;
        width: 80%;
        padding-right: 25px;
        display: inline !important;
    }

    .searchinput {
        width: 100% !important;
        margin-bottom: 10px;
    }

    .locationlist {
        top: 180px;
    }
}

@media only screen and (max-width: 425px) {
    .searchdisplay {
        display: inline !important;
    }

    .paidunpaid {
        margin-top: 60px !important;
    }

    .locationlist {
        top: 235px;
    }

    .displayhead {
        width: 79%;
        display: inline !important;
    }

    .addbtn {
        margin-left: 0 !important;
    }

    .search {
        margin-bottom: 10px;
    }

    .searchinput {
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 375px) {
    .divWidth {
        width: 1000px !important;
    }
}