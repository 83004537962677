p {
    font-size: 14px;
}

.locationmain {
    top: 50%;
    position: relative;
    border-radius: 10px;
    margin-bottom: 10px;
    height: 70px;
}

.pagination {
    background-color: #5B93FF;
    border-radius: 50%;
    color: white;
}

.activeStatus {
    border-radius: 22.5px;
    background: #3a974d57;
    width: 150px;
    display: flex;
    border: none;
    padding: 10px;
    color: green;
    align-items: center;
    justify-content: center;
}

.closedStatus {
    border-radius: 22.5px;
    background: #d11a2960;
    width: 150px;
    display: flex;
    border: none;
    padding: 10px;
    color: #D11A2A;
    align-items: center;
    justify-content: center;
}

.pendingStatus {
    border-radius: 22.5px;
    background: #f2923938;
    width: 150px;
    display: flex;
    border: none;
    padding: 10px;
    color: #F29339;
    align-items: center;
    justify-content: center;
}

.statusinfo {
    padding: 10px 10px;
    margin-bottom: 0 !important;
    color: #3A974C;
}

.dropbtn::before {
    content: none !important;
}

.dropbtn:hover {
    color: black;
}

.dropbtn:focus {
    box-shadow: none;
}

.dropbtn:focus svg path,
.dotbtn:focus svg path {
    fill: #5B93FF !important;
}

.dropbtn {
    background-color: transparent !important;
    border: none !important;
}

.dotbtn {
    background-color: transparent !important;
    border: none !important;
    font-size: 30px;
}

.label {
    margin-bottom: 15px;
}

.formgroup {
    margin-bottom: 16px;
}

.dotbtnmenu {
    width: 305px;
    margin-top: 45px !important;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.10);
    text-decoration: none;
    list-style: none;
}

.dotbtn::before {
    content: none !important;
}

.dotbtn:hover {
    color: #B3B3BF !important;
}

.dotbtn:focus {
    box-shadow: none !important;
}

.dropmenu {
    width: auto;
    margin-top: 45px !important;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.10);
    text-decoration: none;
    list-style: none;
}

.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    bottom: 0;
}

.loadingSpinner {
    color: #5B93FF;
    width: 10px !important;
}

.dropmenu p {
    margin-bottom: 0;
}

.editBtn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    background: rgba(91, 147, 255, 0.05);
    padding: 5px 20px;
    border: none;
    color: #5B93FF;
    border-radius: 5px;
}

.deleteBtn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    background: rgba(231, 29, 54, 0.05);
    padding: 5px 20px;
    color: #E71D36;
    border: none;
    border-radius: 5px;
}

.statusBtn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    background: rgba(242, 147, 57, 0.05);
    padding: 5px 20px;
    border: none;
    color: #F29339;
    border-radius: 5px;
}

.addmachineBtn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    background: rgba(91, 147, 255, 0.05);
    padding: 5px 20px;
    border: none;
    color: #3A974C;
    border-radius: 5px;
}

.addBtn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    background: rgba(58, 151, 76, 0.05);
    padding: 5px 20px;
    border: none;
    color: #3A974C;
    border-radius: 5px;
}

.payBtn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    background: rgba(91, 147, 255, 0.05);
    padding: 5px 20px;
    border: none;
    color: #FFC42B;
    border-radius: 5px;
}

.modal-dialog {
    max-width: 900px !important;
    height: 1000px !important;
}

.selectbox {
    display: flex;
    align-items: center;
    height: 3rem;
    border: none;
    background-color: #F7F7F8;
}

.label {
    margin-bottom: 15px;
}

.formgroup {
    margin-bottom: 16px;
}

.closebtn,
.closebtn:hover {
    border: 1px solid #5B93FF;
    color: #5B93FF;
    border-radius: 10px;
    background-color: transparent;
    padding: 7px 25px;
}

.createbtn {
    border: 1px solid #5B93FF;
    color: white;
    border-radius: 10px;
    background-color: #5B93FF;
    padding: 7px 25px;
}

.crossBtn {
    background-color: #eb7777;
    border-radius: 50%;
    padding: 13px !important;
}

@media only screen and (max-width: 768px) {
    .locationlistmain {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .locationmain {
        padding-top: 18px;
        padding-bottom: 18px;
    }
}