.bglocation {
  background-color: #5b92ff52;
  padding: 20px 20px;
  border-radius: 50%;
}

.bgmachine {
  background-color: #ffd56b69;
  padding: 20px 20px;
  border-radius: 50%;
}

.bgadmin {
  background-color: #ff906b4f;
  padding: 20px 20px;
  border-radius: 50%;
}

.bgemployee {
  background-color: #5b92ff52;
  padding: 20px 20px;
  border-radius: 50%;
}

.dashboardData {
  padding: 0;
}

.backgroundColorWhite {
  background-color: white;
  padding: 15px;
  border-radius: 15px;
}

@media only screen and (max-width: 1300px) {

}

@media only screen and (max-width: 1024px) {
  .dashboardData {
    padding: 10px;
    padding-left: 0;
  }
  .dashboardmain {
    gap: 0 !important;
  }
}

@media only screen and (max-width: 768px) {

}

@media only screen and (max-width: 320px) {
  .backgroundColorWhite {
    padding: 10px;
    padding-left: 1.5rem !important;
  }
}