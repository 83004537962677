.dashboardhead {
  z-index: 10;
  background-color: #F2F4F9;
  width: 79%;
  position: fixed;
  padding-top: 35px;
  padding-bottom: 35px;
}

.dashboarddata {
  position: relative;
  top: 117px;
}

.backgroundColore h5 {
  opacity: 0.7;
}

.dropbtn {
  border: 0;
  padding: 15px;
  border-radius: 10px;
  background-color: #FFFFFF;
}

.linechart {
  background-color: white;
  height: 340px;
  padding: 10px 10px;
  border-radius: 15px;
}

.donutchart{
  background-color: white;
  height: 340px;
  padding: 10px 10px;
  border-radius: 15px;
}
.collection{
  z-index: 20;
  position: relative;
}
.chart {
  padding: 5px;
  height: 280px;
  width: 100%;
}

.piechart{
  padding: 5px;
  width: 100%;
  height: 225px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1024px) {
  .dashboardhead {
    width: 69%;
  }

  .backgroundColore {
    overflow: auto;
  }
}

@media only screen and (max-width: 768px) {
  .dashboardhead {
    width: 73%;
    padding-right: 25px;
  }
}

@media only screen and (max-width: 600px) {
  .dashboardhead {
    display: inline !important;
  }
}

@media only screen and (max-width: 425px) {
  .dropbtn {
    margin-right: 0 !important;
    padding: 10px;
  }

  .dashboarddata {
    top: 130px;
  }
}

@media only screen and (max-width: 375px) {
  .dropbtn {
    font-size: 12px;
  }
}