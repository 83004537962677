.repairhead {
    z-index: 13;
    background-color: #F2F4F9;
    width: 79%;
    position: fixed;
    padding-top: 35px;
    padding-bottom: 30px;
    top: 0;
}

.repairlist {
    position: relative;
    top: 20px;
}

.searchinput {
    width: 25% !important;
    height: 40px;
}

.searchdisplay {
    height: 40px;
}

.search {
    height: 100%;
}

.searchfield {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.searchbtn {
    background-color: white;
    border: none;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    line-height: 0;
}

.searchbtn:hover {
    background-color: white !important;
}

.addbtn {
    background-color: #5B93FF;
    border-radius: 10px !important;
    color: white;
    margin-left: 10px;
    border: none;
    padding: 10px 10px;
    display: inline-flex;
    align-items: center;
}

.closebtn {
    border: 1px solid #5B93FF;
    color: #5B93FF;
    border-radius: 10px;
    background-color: transparent;
    padding: 7px 25px;
}

.headingFont {
    font-size: 24px !important;
    font-weight: 700 !important;
    color: #030229;
}

.createbtn {
    border: 1px solid #5B93FF;
    color: white;
    border-radius: 10px;
    background-color: #5B93FF;
    padding: 7px 25px;
}

.sdcontainer {
    position: relative;
    float: left;
  }
  
  .sd {
    padding: 5px 10px;
    height: 30px;
    width: 150px;
  }
  
  .openbutton {
    position: absolute;
    top: 100px;
    left: 121px;
    width: 25px;
    height: 25px;
    background: #fff;
    pointer-events: none;
  }
  
  .openbutton button {
    border: none;
    background: transparent;
  }

@media only screen and (max-width: 1200px) {
    .divWidth {
        width: 1000px !important;
    }
    .repairhead{
        width: 70%;
    }
}

@media only screen and (max-width: 1024px) {

    .repair {
        overflow: auto;
    }

    .repairlist {
        top: 70px;
    }

    .repairhead {
        width: 72%;
        padding-right: 25px;
    }
}

@media only screen and (max-width: 860px) {
    .repairlist {
        top: 90px;
    }
    .repairhead{
        display: inline !important;
    }
}

@media only screen and (max-width: 768px) {
    .divWidth {
        width: 1125px !important;
    }

    .addbtn {
        font-size: 12px;
    }

    .repairhead {
        width: 80%;
    }
}

@media only screen and (max-width: 425px) {
    .divWidth {
        width: 950px !important;
    }

    .repairhead {
        width: 79%;
    }

    .search {
        margin-bottom: 15px;
    }

    .repairlist {
        top: 90px;
    }

    .addbtn {
        margin-left: 0;
    }
}