.mainlist {
    position: relative;
    top: 80px;
}

.statusbtn {
    border-radius: 22.5px;
    background: #3a974d57;
    width: 150px;
    display: flex;
    border: none;
    padding: 10px;
    color: green;
    align-items: center;
    justify-content: center;
}

.closebtn,
.closebtn:hover {
    border: 1px solid #5B93FF;
    color: #5B93FF;
    border-radius: 10px;
    background-color: transparent;
    padding: 7px 25px;
}

.rowfontsize {
    font-size: 14px;
    font-weight: 600;
    height: 70px;
    margin-bottom: 10px;
    border-radius: 10px;
}

.pagination {
    background-color: #5B93FF;
    border-radius: 50%;
    color: white;
}

.statusinfo {
    padding: 10px 10px;
    margin-bottom: 0 !important;
    color: #3A974C;
}

.dropbtn {
    background-color: transparent !important;
    color: #B3B3BF;
    border: none !important;
    font-size: 30px;
    line-height: 0 !important;
}

.dropbtn::before {
    content: none !important;
}

.dropbtn:hover {
    color: black;
}

.dropbtn:focus {
    box-shadow: none;
}

.dropbtn:focus svg path {
    fill: #5B93FF !important;
}

.dropmenu {
    width: 200px;
    margin-top: 45px !important;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.10);
    text-decoration: none;
    list-style: none;
}

.editbtn {
    padding: 5px 20px;
    border: none;
    color: #5B93FF;
    background-color: rgba(91, 147, 255, 0.05);
    border-radius: 6px;
}

.blocked {
    color: #E71D36;
}

.unblocked{
    color:#3A974C;
}

.selectbox {
    border: none;
    background-color: #F7F7F8;
}

.checkboxinput {
    min-height: 0 !important;
}

.checkboxsize {
    font-size: 16px;
    margin-right: 25px;
    margin-left: 25px !important;
    height: 20px;
    width: 20px;
    border-radius: 2px;
}

.paidbtn {
    padding: 5px 20px;
    color: #3A974C;
    border: none;
    background-color: rgba(91, 147, 255, 0.05);
    border-radius: 6px;
}

.selectbox {
    padding: 7px 10px;
    height: 3rem;
    border: none;
    background-color: #F7F7F8;
    font-size: 13px;
}

.blockbtn {
    padding: 5px 20px;
    border: none;
    color: #E71D36;
    background: rgba(231, 29, 54, 0.05);
    border-radius: 6px;
}

.unblockbtn {
    padding: 5px 20px;
    border: none;
    color: #3A974C;
    background-color: rgba(91, 147, 255, 0.05);
    border-radius: 6px;
}

.modal-dialog {
    max-width: 900px !important;
    height: 1000px !important;
}

.label {
    margin-bottom: 15px;
}

.formgroup {
    margin-bottom: 16px;
}

.closebtn {
    border: 1px solid #5B93FF;
    color: #5B93FF;
    border-radius: 10px;
    background-color: transparent;
    padding: 7px 25px;
}

.createbtn {
    border: 1px solid #5B93FF;
    color: white;
    border-radius: 10px;
    background-color: #5B93FF;
    padding: 7px 25px;
}

.crossBtn {
    background-color: #eb7777;
    border-radius: 50%;
    padding: 13px !important;
}

.custommodalcolor {
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-backdrop.show {
    opacity: 0.1 !important;
}

@media only screen and (max-width: 768px) {
    .mainlist {
        top: 100px;
    }
}

@media only screen and (max-width: 530px) {
    .mainlist {
        top: 180px;
    }
}

@media only screen and (max-width: 375px) {}