.servicehead {
    z-index: 10;
    background-color: #F2F4F9;
    width: 79%;
    position: fixed;
    padding-top: 35px;
    padding-bottom: 35px;
    top: 0;
}

.servicereportlist {
    position: relative;
    top: 80px;
}

.searchinput {
    background-color: white;
    width: 25% !important;
    border-radius: 10px;
    height: 40px;
}

.searchbtn:hover {
    background-color: transparent;
    color: gray;
}

.searchbtn {
    line-height: 0;
}

.searchfield {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    background-color: white !important;
}

.addbtn {
    background-color: #5B93FF;
    border-radius: 10px !important;
    color: white;
    margin-left: 10px;
    border: none;
    padding: 10px 10px;
}


/* modal */
.modal-dialog {
    max-width: 900px !important;
    height: 1000px !important;
}

.inputfield {
    border: none;
    padding: 5px 15px;
}


.closebtn {
    border: 1px solid #5B93FF;
    color: #5B93FF;
    border-radius: 10px;
    background-color: transparent;
    padding: 7px 25px;
}

.createbtn {
    border: 1px solid #5B93FF;
    color: white;
    border-radius: 10px;
    background-color: #5B93FF;
    padding: 7px 25px;
}

@media only screen and (max-width: 1024px) {
    .servicehead {
        width: 72%;
        padding-right: 25px;
    }

    .servicereport {
        overflow: auto;
    }
}

@media only screen and (max-width: 768px) {
    .searchinput {
        width: 50% !important;
    }

    .divwidth {
        width: 685px;
    }
}

@media only screen and (max-width: 425px) {
    .servicehead {
        display: inline !important;
    }

    .searchinput {
        width: 100% !important;
    }

    .servicehead {
        width: 79%;
    }

    .servicereportlist {
        top: 90px;
    }
}