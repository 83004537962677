.collectionlist p{
    font-size: 12px;
}

.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 600px;
    left: 0px;
    right: 600px;
    bottom: 0;
}

.loadingSpinner {
    color: #5B93FF;
    width: 20px !important;
    height: 20px !important;
}