* {
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  color: #030229;
}

.row {
  --bs-gutter-x: 0 !important;
  /* Your custom gutter value */
}


:root {
  --bs-primary: #5B93FF;
}

.row {
  --bs-gutter-x: 0 !important;
}

input::-webkit-input-placeholder {
  color: #030229 !important;
  opacity: 0.7;
  font-size: 13px;
}

.form-control {
  border: none;
  background-color: #F7F7F8;
  border-radius: 5px;
  padding: 18px 20px;
  color: #030229 !important;
  opacity: 0.7;
  font-size: 13px;
}

.form-control:focus {
  box-shadow: none;
  background-color: #a6aab91c;
}

.form-check-label {
  font-size: 14px;
}

.btn:focus {
  box-shadow: none !important;
}

a {
  text-decoration: none !important;
}

a:hover {
  color: #F7F7F8;
}

.accordion-button:not(.collapsed) {
  color: black !important;
  background-color: white !important;
  box-shadow: none !important;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button:not(.collapsed)::after {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="%23000"%3e%3cpath fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/%3e%3c/svg%3e');
  transform: rotate(-180deg);
}

/* modal css */

.modal-content {
  border-radius: 10px !important;
  border: none;
  padding: 30px 22px;
}

.modal-header {
  border: none !important;
  padding: 0;
  margin-bottom: 28px;
}

.modal-footer {
  border: none !important;
  padding: 0;
  margin-top: 28px;
}

.form-select:focus {
  box-shadow: none;
}

.modal-body {
  padding: 0;
}

.custom-backdrop {
  opacity: 0.1 !important;
}


.form-label {
  color: #030229;
  font-size: 15px;
  font-weight: 400;
}

.form-select {
  color: #030229;
  font-weight: 400;
  opacity: 0.7;
}

.btn-close {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23E71D36'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  background-size: 10px 10px;
  background-color: rgba(231, 29, 54, 0.05);
  padding: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
}

.btn-close:focus {
  box-shadow: none;
}


/* typehead */
.rbt-input-wrapper {
  display: flex;
  gap: 7px;
  flex-wrap: wrap;
  align-items: center;
}

.rbt-token {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F7F7F8;
  gap: 7px;
  padding: 3px;
}

.rbt-input-main::placeholder {
  color: #030229 !important;
  opacity: 0.7;
}

.rbt-close {
  font-size: 12px;
}

.rbt-close-content {
  display: none;
}

.rbt-input-multi {
  height: auto;
}

.pagination svg {
  width: 32px;
  height: 32px;
}

.react-datepicker__calendar-icon{
  width: 23em !important;
}
