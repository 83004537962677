.bggradient {
    background: linear-gradient(90deg, rgba(91, 147, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 37.22%);
}

.bggradient p {
    color: #5B93FF;
}

.bggradient svg path {
    stroke: #5B93FF;
}

.startpadding {
    padding-left: 4rem;
}

.userinfo {
    margin-top: 200px;
}

.sidbermenuactive {
    color: #5B93FF;
}

.toggle {
    font-size: 30px;
}

.togglebtn {
    font-size: 30px;
    padding: 0 !important;
}

.sidebar {
    /* your existing styles for the sidebar */
    /* ... */
    transition: transform 0.3s ease-in-out;
}

.open {
    transform: translateX(0);
    position: fixed;
    width: inherit;
    top: 0;
    bottom: 0;
}

.closed {
    transform: translateX(-100%);
}

/* Media queries for responsiveness */
@media only screen and (max-width: 1024px) {
    .headingroute {
        font-size: 20px;
    }

    .marginlogo {
        margin-bottom: 10px !important;
    }

    .bggradient {
        padding: 0 !important;
    }

    .navlinkpadding {
        padding: 0 !important;
    }
}

@media (max-width: 765px) {
    .sidebarMenu {
        display: none !important;
    }

    .sidebarmenu {
        margin-left: 60px;
    }

    .menu {
        margin-left: 60px;
        padding: 10px;
        align-items: center;
    }

    .headingmenu {
        margin-left: 50px;
    }

    .startpadding {
        display: inline-flex !important;
        text-align: center;
        padding-left: 0 !important;
    }

    .togglemenu {
        text-align: center;
    }
}

@media (max-width: 768px) {
    .sideBarDiv {
        width: 50% !important;
    }

    .startpadding {
        padding-left: 3rem;
    }

    .marginlogo {
        margin-top: 1rem !important;
        margin-bottom: 10px !important;
    }

    .sideBarDiv {
        width: 100%;
    }

    .sidebar {
        width: 100%;
        max-width: 100%;
        position: fixed;
        height: 100%;
        transform: translateX(-100%);
        z-index: 1000;
    }

    .closed {
        transform: translateX(-100%);
    }

    .open {
        transform: translateX(0);
    }

    .toggle {
        position: absolute;
        left: 44rem;
    }
}


@media (max-width: 425px) {
    .sideBarDiv {
        width: 50% !important;
    }

    .userinfo {
        display: none !important;
    }

    .marginlogo {
        margin-left: 0 !important;
    }

    .marginlogo {
        margin-top: 1rem !important;
        margin-bottom: 0 !important;
    }

    .sidebarMenu {
        display: none;
    }

    .togglebtn {
        padding: 0;
        margin: 0;
    }

    .togglebtn svg {
        font-size: 25px;
        color: rgb(154, 154, 169);
    }

    .menu  {
        margin-left: 40px;
    }

    .headingmenu {
        margin-left: 30px;
    }

    .sideBarDiv {
        width: 60% !important;
    }
}

@media (max-width: 375px) {
    .headingmenu {
        margin-left: 10px;
    }
}

@media (max-width: 320px) {

    .startpadding {
        padding-left: 16px !important;
    }

    .fontSizeRouter {
        font-size: 10px;
    }
}