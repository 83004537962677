.dropbtn {
    font-size: 12px;
    color: #030229;
}

.collectionreporthead {
    z-index: 10;
    background-color: #F2F4F9;
    position: sticky;
    top: 100px;
}

.collectionreportilst {
    position: relative;
    top: 100px;
}

.fontsize {
    font-size: 14px;
}

.checkBtn {
    height: 20px;
    width: 20px;
    border-radius: 10px;
}

@media only screen and (max-width: 1024px) {
    .divwidth {
        width: 1200px;
    }
}

@media only screen and (max-width: 768px) {
    .collectionreporthead {
        margin-top: 0 !important;
    }

    .divwidth {
        width: 1200px;
    }
}

@media only screen and (max-width: 425px) {
    .collectionreporthead {
        top: 125px;
    }

    .collectionreportilst {
        top: 150px;
    }
}