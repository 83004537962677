.adminhead {
  z-index: 10;
  background-color: #F2F4F9;
  top: 90px;
  position: sticky;
  margin-top: 5px;
}

.dropbtn:focus {
  box-shadow: none !important;
}

.dropbtn {
  padding: 0 !important;
  font-size: 12px;
  font-weight: 400;
  color: #030229;
  opacity: 0.7;
}
.dropbtn:hover {
  cursor: default !important;
}
.checkboxinput {
  min-height: 0 !important;
  font-size: 16px;
}

.checkboxsize {
  font-size: 16px;
  margin-right: 25px;
  margin-left: 25px !important;
  height: 20px;
  width: 20px;
  border-radius: 2px;
}

.adminlist {
  /* display: inline-block; */

  margin-right: 10px;
  /* Adjust the margin between items if needed */
}

.bottomborder {
  margin-top: 5px;
  border-bottom: 1px solid #000;
  /* Apply the bottom border */
}

@media only screen and (max-width: 600px) {
  .adminhead {
    top: 150px;
  }
}
@media only screen and (max-width: 768px) {
  .adminhead {
    top: 120px;
  }
}

@media only screen and (max-width: 530px) {
  .adminhead {
    top: 180px;
  }
}