.outletbg {
    height: 100%;
    background-color: #F2F4F9;
    padding-left: 20px;
    padding-right: 20px;
    overflow: auto;

}

@media only screen and (max-width: 768px) {
    .outletbg {
        padding-left: 5px;
        padding-right: 5px
    }
}

@media only screen and (max-width: 1024px) {
    .outletbg {
        overflow: hidden !important;
    }
}