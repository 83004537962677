.employeehead {
    z-index: 10;
    background-color: #F2F4F9;
    margin-right: 50px;
    width: 80%;
    position: fixed;
    padding-top: 35px;
    padding-bottom: 35px;
    top: 0;
}

.employeelist {
    position: relative;
    top: 120px;
}

.adminmain {
    height: 100%;
}

.searchinput {
    width: 40% !important;
}

.label {
    margin-bottom: 15px;
}

.formgroup {
    margin-bottom: 16px;
}

.searchdisplay {
    height: 40px;
}

.searchfield {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
}

.closebtn,
.closebtn:hover {
    border: 1px solid #5B93FF;
    color: #5B93FF;
    border-radius: 10px;
    background-color: transparent;
    padding: 7px 25px;
}

.searchbtn {
    background-color: white;
    border: none;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    line-height: 0;
}

.searchbtn:hover {
    background-color: white !important;
}

.addbtn {
    background-color: #5B93FF;
    border-radius: 10px !important;
    color: white;
    margin-left: 10px;
    border: none;
    padding: 10px 10px;
    width: fit-content;
}


/* modal */
.modal-dialog {
    max-width: 900px !important;
    height: 1000px !important;
}

.closebtn {
    border: 1px solid #5B93FF;
    color: #5B93FF;
    border-radius: 10px;
    background-color: transparent;
    padding: 7px 25px;
}

.createbtn {
    border: 1px solid #5B93FF;
    color: white;
    border-radius: 10px;
    background-color: #5B93FF;
    padding: 7px 25px;
}

@media only screen and (max-width: 1300px) {
    .divWidth {
        width: 1250px !important;
    }
}

@media only screen and (max-width: 1200px) {
    .employeehead {
        width: 72%;
        padding-right: 25px;
    }

    .employeelist {
        top: 120px;
    }
}

@media only screen and (max-width: 1024px) {
    .divWidth {
        width: 1200px !important;
    }

    .employee {
        overflow: auto;
    }

    .employeehead {
        width: 72%;
        padding-right: 25px;
    }
}

@media only screen and (max-width: 860px) {
    .employeehead {
        display: inline !important;
    }

    .employeelist {
        top: 135px;
    }
}

@media only screen and (max-width: 768px) {
    .divWidth {
        width: 1100px !important;
    }

    .addbtn {
        font-size: 12px;
    }

    .employeehead {
        width: 80%;
    }
}

@media only screen and (max-width: 425px) {
    .searchdisplay {
        display: inline !important;
    }

    .employeehead {
        width: 79%;
    }

    .search {
        margin-bottom: 15px;
    }

    .divWidth {
        width: 1220px !important;
    }

    .employeelist {
        top: 200px;
    }

    .addbtn {
        margin-left: 0;
    }
}

@media only screen and (max-width: 375px) {
    .divWidth {
        width: 1000px !important;
    }
}