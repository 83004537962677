.mainadmin {
    z-index: 10;
    background-color: #F2F4F9;
    width: 79%;
    position: fixed;
    top: 0;
    padding-bottom: 35px;
    padding-top: 35px;
}

.closebtn,
.closebtn:hover {
    border: 1px solid #5B93FF;
    color: #5B93FF;
    border-radius: 10px;
    background-color: transparent;
    padding: 7px 25px;
}

.width {
    width: auto;
    height: 40px;
}

.searchinput {
    width: 40% !important;
}

.searchbtn {
    background-color: white;
    border: none;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.searchbtn:hover {
    background-color: white !important;
}

.searchbtn:focus {
    box-shadow: none !important;
}


.mainheadingFont {
    font-size: 24px !important;
    font-weight: 700 !important;
    color: #030229;
}

.addbtn {
    background-color: #5B93FF;
    border-radius: 10px !important;
    color: white;
    margin-left: 10px;
    border: none;
    padding: 10px 10px;
}

.customRadio {
    border: none;
    background-color: white; /* Default background color for inactive state */
  }
  
  .customRadio:checked {
    background-color: #5B93FF !important;
  }


/* modal */
.modal-dialog {
    max-width: 900px !important;
    height: 1000px !important;
}

.label {
    margin-bottom: 15px;
}

.formgroup {
    margin-bottom: 16px;
}

.closebtn {
    border: 1px solid #5B93FF;
    color: #5B93FF;
    border-radius: 10px;
    background-color: transparent;
    padding: 7px 25px;
}

.createbtn {
    border: 1px solid #5B93FF;
    color: white;
    border-radius: 10px;
    background-color: #5B93FF;
    padding: 7px 25px;
}

.createbtn:disabled {
    opacity: 0.5;
}

.searchfield {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

@media only screen and (max-width: 1200px) {
    .divwidth {
        width: 968px;
    }

    .admin {
        overflow: auto;
        height: 100%;
    }

    .mainadmin {
        width: 72%;
        padding-right: 25px;
    }
}

@media only screen and (max-width: 600px) {
    .mainadmin {
        width: 82% !important;
    }
}

@media only screen and (max-width: 765px) {
    .adminhead {
        display: inline !important;
    }
}

@media only screen and (max-width: 768px) {
    .divwidth {
        width: 1025px;
    }

    .mainadmin {
        width: 73%;
    }

    .width {
        margin-top: 15px;
    }

    .mainadmin {
        display: inline !important;
    }
}

@media only screen and (max-width: 530px) {
    .addbtn {
        margin-top: 15px;
    }
}

@media only screen and (max-width: 425px) {
    .divwidth {
        width: 1015px;
    }

    .mainadmin {
        width: 79%;
        margin-bottom: 65px;
    }

    .search {
        display: inline !important;
    }

    .searchmenu {
        margin-bottom: 25px;
    }

    .addbtn {
        margin-left: 0 !important;
        margin-top: 15px;
    }
}