
.backgroundColor {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  height: 300px;
  margin-right: 15px;
}

.dropbtn:focus {
  box-shadow: none !important;
}

.dropbtn {
  padding: 0 !important;
  background-color: white;
  color: gray;

}

.dropbtn::before {
  content: none !important;
}

.dropbtn:hover {
  color: black;
}

.dropbtn:focus {
  box-shadow: none;
}

.dropbtn {
  background-color: transparent !important;
  border: none !important;
  font-size: 30px;

}

.dropmenu {
  width: 250px;
  margin-top: 45px !important;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.10);
  text-decoration: none;
  list-style: none;
}

.dropdownfontsize {
  font-size: 12px;
  color: #030229;
}
@media only screen and (max-width: 1024px) {
  .pendingRepaires {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }
}

@media only screen and (max-width: 768px) {
  .backgroundColor {
    padding: 2rem !important;
    margin-top: 1rem;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }


  .dropdownfontsize {
    font-size: 10px;
  }
}

@media only screen and (max-width: 425px) {
  .divwidth {
    width: 400px !important;
  }
}

@media only screen and (max-width: 375px) {
  .divwidth {
    width: 450px !important;
  }

}

@media only screen and (max-width: 320px) {
  .divwidth {
    width: 415px !important;
  }
}

@media only screen and (max-width: 1440px) {
  .backgroundColor {
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 425px) {
  .backgroundColor {
    padding: 0.5rem !important;
  }

  .padding {
    padding: 0 !important;
  }
}

@media only screen and (max-width: 1024px) {
  .padding {
    padding: 0 !important;
  }
  .backgroundColor{
    margin-right: 0;
    margin-bottom: 15px;
  }
}