.searchinput {
    width: 25% !important;
    height: 40px;
}
.mainreport{
    
}
.searchbtn:hover {
    background-color: transparent;
    color: gray;
}

.reportdata {
    position: relative;
    top: 120px;
}

.addbtn {
    background-color: #5B93FF;
    border-radius: 10px !important;
    color: white;
    margin-left: 10px;
    border: none;
    padding: 10px 10px;
}

.searchbtn {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    line-height: 0;
}

.inputfield {
    border: none;
    padding: 5px 15px;
}

.reporthead {
    z-index: 10;
    background-color: #F2F4F9;
    margin-right: 50px;
    width: 79%;
    position: fixed;
    padding-top: 35px;
    padding-bottom: 35px;
    top: 0;
}

.chart {
    background-color: white;
    border-radius: 10px;
    margin-bottom: 20px;
}

.closebtn {
    border: 1px solid #5B93FF;
    color: #5B93FF;
    border-radius: 10px;
    background-color: transparent;
    padding: 7px 25px;
}

.btn {
    color: black;
    border: none;
    background-color: white;
}

.createbtn {
    border: 1px solid #5B93FF;
    color: white;
    border-radius: 10px;
    background-color: #5B93FF;
    padding: 7px 25px;
}

.headingFont {
    font-size: 24px !important;
    font-weight: 700 !important;
    color: #030229;
}

.linechart {
    height: 350px;
}

@media only screen and (max-width: 1024px) {
    .mainreport{
        overflow: auto;
        height: 100%;
    }
    .reporthead {
        width: 72%;
        padding-right: 25px;
        overflow: auto;
    }
}

@media only screen and (max-width: 768px) {
    .searchinput {
        width: 50% !important;
    }

    .margin {
        padding-top: 1rem !important;
    }

    .btn {
        padding: 0.3rem 1rem !important;
    }

}

@media only screen and (max-width: 425px) {
    .btnpadding {
        padding-left: 1.5rem;
        padding-right: 0 !important;
    }

    .reporthead {
        width: 79%;
        display: inline !important;
    }

    .reportdata {
        top: 140px;
    }

    .reportmenu {
        display: inline !important;
    }

    .searchfield {
        display: inline !important;
    }

    .searchinput {
        margin-top: 10px;
        width: 100%;
    }

    .chart {
        margin-top: 20px;
    }

    .searchinput {
        width: 100% !important;
    }
}

@media only screen and (max-width: 375px) {}

@media only screen and (max-width: 375px) {


    .btn {
        padding: 0.3rem 0.5rem !important;
    }
}