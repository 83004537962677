.dashboardhead {
  z-index: 10;
  background-color: #F2F4F9;
  width: 79%;
  position: fixed;
  padding-top: 35px;
  padding-bottom: 35px;
  top: 0;
}

.dashboarddata {
  position: relative;
  top: 68px;
}

.backgroundColore {
  padding-top: 35px;
}

.backgroundColore h5 {
  opacity: 0.7;

}

.dropbtn {
  border: 0;
}

.collection {
  margin-bottom: 23px;
}

.linechart {
  background-color: white;
  padding: 15px 15px;
  border-radius: 15px;
  margin-right: 15px;
}

.chart {
  padding: 5px;
  height: 280px;
  width: 100%;
}

.backgroundColorWhite {
  padding: 15px;
  border-radius: 10px;
}

@media only screen and (max-width: 600px) {
  .dashboardhead {
    display: inline !important;
  }
  .dashboarddata{
    top: 100px;
  }
}

@media only screen and (max-width: 425px) {

  .backgroundColorWhite {
    margin-right: 0 !important;
  }
}

@media only screen and (max-width: 1024px) {
  .linechart {
    margin-right: 0;
  }

  .dashboardhead {
    width: 69%;
  }
}

@media only screen and (max-width: 768px) {
  .dashboardhead {
    width: 70%;
  }
}

@media only screen and (max-width: 425px) {
  .dashboardhead {
    width: 79%;
  }

  .dashboarddata {
    top: 100px;
  }
}

@media only screen and (max-width: 375px) {
  .dropbtn {
    font-size: 12px;
  }
}

@media only screen and (max-width: 320px) {
  .dropbtn {
    padding: 14px;
  }
}