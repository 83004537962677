.repairhead {
  z-index: 10;
  background-color: #F2F4F9;
  position: sticky;
  top: 40px;
  padding-top: 70px;
}

.dropbtn:focus {
  box-shadow: none !important;
}

.dropbtn {
  padding: 0 !important;
  font-size: 12px;
}

.adminlist {
  /* display: inline-block; */

  margin-right: 10px;
  /* Adjust the margin between items if needed */
}

.bottomborder {
  margin-top: 5px;
  border-bottom: 1px solid #000;
  /* Apply the bottom border */
}


@media only screen and (max-width: 860px) {
  .repairhead {
    top: 120px;
  }
}

@media only screen and (max-width: 768px) {
  .repairhead {
    top: 125px;
  }
}

@media only screen and (max-width: 425px) {
  .repairhead {
    top: 180px;
  }
}
