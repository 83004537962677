.mainadmin {
  z-index: 10;
  background-color: #F2F4F9;
  position: sticky;
  top: 100px;
}

.machinepadding {
  width: 25%;
}

.employeepadding {
  width: 15%;
}

.locationpadding {
  width: 10%;
}

.intialpadding {
  width: 10%;
}

.currentpadding {
  width: 15%;
}

.statuspadding {
  width: 25%;
}

.dropbtn:focus {
  box-shadow: none !important;
}

.dropbtn {
  padding: 0 !important;
  font-size: 12px;
}

.adminlist {
  margin-right: 10px;
}

.bottomborder {
  margin-top: 5px;
  border-bottom: 1px solid #000;
  /* Apply the bottom border */
}

@media only screen and (max-width: 768px) {
  .mainadmin {
    top: 140px;
  }
}
@media only screen and (max-width: 425px) {
  .mainadmin {
    top: 200px;
  }
  
}