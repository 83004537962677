.statusbtn {
    border-radius: 22.5px;
    background: #3a974d57;
    width: 150px;
    display: flex;
    border: none;
    padding: 10px;
    color: green;
    align-items: center;
    justify-content: center;
}

.createbtn {
    border: 1px solid #5B93FF;
    color: white;
    border-radius: 10px;
    background-color: #5B93FF;
    padding: 7px 25px;
}

.closebtn {
    border: 1px solid #5B93FF;
    color: #5B93FF;
    border-radius: 10px;
    background-color: transparent;
    padding: 7px 25px;
}

.lineheight {
    line-height: 0 !important;
}

.checkBtn {
    height: 20px;
    width: 20px;
    border-radius: 10px;
}

.backgroundColor {
    background-color: #F7F7F8;
    font-size: 12px;
    color: #030229;
    border-radius: 5px 5px 0 0;
}

.bgColoreTableRow {
    background-color: rgb(201 189 189 / 12%);
    font-size: 14px;
    color: #030229;
}

.tableBorderRadious {
    border-radius: 10px;
}

.listrow {
    font-size: 14px;
    height: 70px;
}

.printmodal{
    max-width: 900px !important;
    background-color: transparent !important;
    padding: 0;
}

.fullscreenimage {
    width: 100%;
    height: auto;
    object-fit: contain;
    
  }


@media only screen and (max-width: 768px) {
    .listrow {
        padding-top: 18px;
        padding-bottom: 18px;
    }
}

@media print {
    body * {
      visibility: hidden;
    }
    #data {
      visibility: visible;
    } 
    .printStyles{
    margin-top: 300px;
    }
  }
