.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh; /* Adjust as needed to center vertically in the viewport */
  width: 80%;
}

.loadingSpinner {
  color: #5B93FF; /* Set the color you want */
}