p {
    font-size: 14px;
}

.repairlist {
    height: 70px;
}

.statusbtn {
    border-radius: 22.5px;
    background: #3a974d57;
    width: 150px;
    display: flex;
    border: none;
    padding: 10px;
    color: green;
    align-items: center;
    justify-content: center;
}

.activeStatus {
    border-radius: 22.5px;
    background: #3a974d57;
    width: 150px;
    display: flex;
    border: none;
    padding: 10px;
    color: green;
    align-items: center;
    justify-content: center;
}

.closedStatus {
    border-radius: 22.5px;
    background: #d11a2960;
    width: 150px;
    display: flex;
    border: none;
    padding: 10px;
    color: #D11A2A;
    align-items: center;
    justify-content: center;
}

.pendingStatus {
    border-radius: 22.5px;
    background: #f2923938;
    width: 150px;
    display: flex;
    border: none;
    padding: 10px;
    color: #F29339;
    align-items: center;
    justify-content: center;
}

.statusinfo {
    padding: 10px 10px;
    margin-bottom: 0 !important;
    color: #3A974C;
}

.dropbtn::before {
    content: none !important;
}

.dropbtn:hover {
    color: black;
}

.dropbtn:focus {
    box-shadow: none;
}

.dropbtn {
    background-color: transparent !important;
    color: blue !important;
    border: none !important;
    font-size: 30px;
    margin-bottom: 30px;
    margin-right: 20px;

}

.dropmenu {
    width: 250px;
    margin-top: 45px !important;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.10);
    text-decoration: none;
    list-style: none;
}

@media only screen and (max-width: 768px) {
    .repairlist {
        padding-top: 18px;
        padding-bottom: 18px;
    }
}