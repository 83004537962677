.searchinput {
    width: 50% !important;
}

.searchfield {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
}

.machinhead {
    z-index: 10;
    background-color: #F2F4F9;
    width: 79%;
    position: fixed;
    padding-top: 35px;
    padding-bottom: 35px;
    top: 0;
}

.machinlist {
    position: relative;
    top: 125px;
}

.closebtn,
.closebtn:hover {
    border: 1px solid #5B93FF;
    color: #5B93FF;
    border-radius: 10px;
    background-color: transparent;
    padding: 7px 25px;
}

.searchbtn {
    background-color: white;
    border: none;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    line-height: 0;
}

.searchbtn:hover {
    background-color: white !important;
}

.paidunpaid {
    border-radius: 5px !important;
}

.searchdisplay {
    height: 40px;
}

.addbtn {
    background-color: #5B93FF;
    border-radius: 10px !important;
    color: white;
    margin-left: 10px;
    border: none;
    padding: 10px 10px;
    display: inline-flex;
    align-items: center;
}

.label {
    margin-bottom: 15px;
}

.selectbox {
    padding: 20px 18px;
    border: none;
    background-color: #F7F7F8;
    color: #030229;
    opacity: 0.7;
    font-size: 13px;
}

.formgroup {
    margin-bottom: 16px;
}

/* modal */
.modal-dialog {
    max-width: 900px !important;
    height: 1000px !important;
}


.closebtn {
    border: 1px solid #5B93FF;
    color: #5B93FF;
    border-radius: 10px;
    background-color: transparent;
    padding: 7px 25px;
}

.createbtn {
    border: 1px solid #5B93FF;
    color: white;
    border-radius: 10px;
    background-color: #5B93FF;
    padding: 7px 25px;
}


/* media screen responsive */
@media only screen and (max-width: 1200px) {
    .divWidth {
        width: 1250px !important;
    }

    .machinelistscroll {
        overflow: auto;
    }

    .machinhead {
        width: 72%;
        padding-right: 25px;
    }
}

@media only screen and (max-width: 1024px) {
    .divWidth {
        width: 1200px !important;
    }

    .machine {
        overflow: auto;
    }

    .machinelistscroll {
        overflow: auto;
        height: 100%;
    }

    .machinhead {
        width: 72%;
        padding-right: 25px;
    }

    .searchinput {
        width: 65% !important;
    }
}

@media only screen and (max-width: 768px) {
    .divWidth {
        width: 1250px !important;
    }

    .machinhead {
        width: 81% !important;
    }

    .machinlist {
        top: 155px;
    }

    .addbtn {
        font-size: 12px;
    }

    .machinelistscroll {
        overflow: auto;
    }

    .machinhead {
        display: inline !important;
    }

    .searchinput {
        width: 100% !important;
        margin-top: 10px;
    }
}

@media only screen and (max-width: 425px) {
    .searchdisplay {
        display: inline !important;
    }

    .search {
        margin-bottom: 15px;
    }

    .machinlist {
        top: 220px;
    }

    .addbtn {
        margin-left: 0 !important;
    }
}

@media only screen and (max-width: 375px) {
    .divWidth {
        width: 1000px !important;
    }

    .machinelistscroll {
        overflow: auto;
    }
}