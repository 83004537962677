.adminhead {
  z-index: 10;
  background-color: #F2F4F9;
  top: 145px;
  position: sticky;
}

.dropbtn:focus {
  box-shadow: none !important;
}

.dropbtn {
  padding: 0 !important;
  font-size: 12px;
}

.adminlist {
  /* display: inline-block; */

  margin-right: 10px;
  /* Adjust the margin between items if needed */
}

.bottomborder {
  margin-top: 5px;
  border-bottom: 1px solid #000;
  /* Apply the bottom border */
}

@media only screen and (max-width: 920px) {
  .adminhead {
    top: 175px;
  }
}

@media only screen and (max-width: 768px) {
  .adminhead {
    top: 180px;
  }
}

@media only screen and (max-width: 425px) {
  .adminhead {
    top: 230px;
  }
}