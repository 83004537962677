.servicereporthead {
  z-index: 10;
  background-color: #F2F4F9;
  position: sticky;
  top: 100px;
}

.dropbtn:focus {
  box-shadow: none !important;
}

.dropbtn {
  padding: 0 !important;
  font-size: 12px;
}
@media only screen and (max-width: 1024px) {
  .servicereporthead {
    top: 90px;
  }
}
@media only screen and (max-width: 425px) {
  .servicereporthead {
    top: 110px;
  }
}