.collectionreporthead {
    z-index: 10;
    background-color: #F2F4F9;
    width: 79%;
    position: fixed;
    padding-top: 35px;
    padding-bottom: 35px;
    top: 0;
}

.searchinput {
    width: 25% !important;
    height: 40px;
}

.searchbtn:hover {
    background-color: transparent;
    color: gray;
}

.searchfield {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
}

.addbtn {
    background-color: #5B93FF;
    border-radius: 10px !important;
    color: white;
    margin-left: 10px;
    border: none;
    padding: 10px 10px;
}

.searchbtn {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    line-height: 0;
}

.inputfield {
    border: none;
    background-color: rgb(112 105 105 / 8%);
    border-radius: 5px;
}
.closebtn {
    border: 1px solid #5B93FF;
    color: #5B93FF;
    border-radius: 10px;
    background-color: transparent;
    padding: 7px 25px;
}

.createbtn {
    border: 1px solid #5B93FF;
    color: white;
    border-radius: 10px;
    background-color: #5B93FF;
    padding: 7px 25px;
}

@media only screen and (max-width: 1024px) {
    .collectionreporthead {
        width: 69%;
    }
    .collectionreport{
        overflow: auto;
    }
}

@media only screen and (max-width: 768px) {
    .searchinput {
        width: 50% !important;
    }

    .collectionreporthead {
        width: 79%;
        padding-right: 20px;
    }
}

@media only screen and (max-width: 425px) {
    .collectionreporthead {
        display: inline !important;
    }

    .maindeading {
        margin-right: 0 !important;
    }

    .searchinput {
        width: 100% !important;
    }
}